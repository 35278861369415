@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
body {
  padding-top: 80px;
  color: #ddd;
  background-color: #292929;
  background-image: url(./pics/triangles.png);
}
.copyright {
  color: #ffffff;
}
.footer {
  text-align: center;
}
.footer a {
  color: #ee4444;
}
.sidebar h1 {
  color: #ddd;
//  font: {
//    size: 1.3em
//  }
}
.sidebar li {
  margin-top: .7em;
  line-height: 1em;
}

.well {
  background-color: #444;
  box-shadow: 0px 0px 0px 0px, 0px 8px 16px rgba(0, 0, 0, 0.2);
  border: 0px;
  border-radius: 6px;
}
.well > main-page {
  width: 75%;
}
.main-page {
  width: 75%;
}

h1 { font-size: 2em; }
h2 { font-size: 1.5em; }
h3 { font-size: 1.17em; }
h4 { font-size: 1.12em; }
h5 { font-size: .83em; }
h6 { font-size: .75em; }

.author {
  color: #ee4444;
}
.author a {
  color: #ee4444;
}

img {
  display: inline-block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
}

img + em {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

blockquote {
  color: #bbb;
  font-size: 1em;
}

.center-image
{
  margin: 0 auto;
  display: block;
}

//********************************************************
//                    Links
//********************************************************
a {
  color: #3CA2A2;
}
a:hover {
  color: #3CA2A2;
  border-bottom: 1px solid transparent;
}

//********************************************************
//                    Social Links
//********************************************************
ul.social-media {
  text-align: center;
  padding: 0 0 10px 0;
  li {
    display: inline-block;
  }
  li > a {
    width: 45px;
    height: 45px;
    @include transition(color 0.2s);
    display: inline-block;
    color: white;
    &:hover {
      color: #3CA2A2;
    }
  }
}

//********************************************************
//                    Post
//********************************************************
h2.post-title {
  padding-top: 10px;
  text-align: left;
//  font-size: 1.6em;
}
div.post-meta {
  border: 1px solid #666666;
  border-width: 1px 0;
  margin: 0 0 20px;
  ul {
    display: inline;
    padding-left: 0px;
    float: right;
  }
  ul > li {
    display: inline;
    padding: 0 0 5px 20px;
  }
  .post-time {
    display: inline;
//    float: right;
  }
}
.post-footer {

  height: 40px;
  display: table;
  width: 100%;
  position: relative;
  margin-top: 32px;
  text-align: center;
}

//    _  _     _
//   | \| |___| |_ ___
//   | .` / _ \  _/ -_)
//   |_|\_\___/\__\___|
//

.note {
  position:relative;
  border: 0;
  padding-left: 30px;
}
.note:before {
  content: "\f06a";
  font-size: 2em;
  font-family: FontAwesome;
  left: 0px;
  position:absolute;
  top: 0;
  color: #87CEFA;
}
.warning:before {
  content: "\f071";
  color: yellow;
}

//    _  _             _
//   | || |___ __ _ __| |___ _ _
//   | __ / -_) _` / _` / -_) '_|
//   |_||_\___\__,_\__,_\___|_|
//
nav a {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  @include transition(color 0.31s);
  font-size: 18px;
  font-family: Ubuntu;
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
}
nav.navbar {
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
}
nav.navbar.shrink {
  min-height: 15px;
}
.navbar-brand {
  font-size: 30px;
  color: #3CA2A2 !important;

}
nav.shrink a {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 15px;
}
nav.shrink .navbar-brand {
  font-size: 25px;
  height: inherit;
}
.navbar .navbar-nav {
  display: inline-block;
  float: right;
}
nav .navbar-toggle {
  margin: 13px 15px 13px 0;
}
nav.shrink .navbar-toggle {
  padding: 4px 5px;
  margin: 8px 15px 8px 0;
}

@media (min-width: 1000px) {
  .twoRow .navbar-collapse {
    clear: left;
  }
  .footer-distributed .footer {
    text-align: center;
  }
  .footer-distributed .footer {
    float: none;
    margin: 0 auto 20px;
  }
  .footer-distributed .footer {
    line-height: 1.8;
  }
}

//********************************************************
//                    Footer
//********************************************************
.footer-distributed {
  background-color: #292c2f;
  font: normal 16px sans-serif;
  padding: 20px 25px;
  margin-top: 80px;
  text-align: center;
  bottom: 0;
  left: 0;
  width: 100%;
  position: absolute;
}


/*----------------------------*\
|    Table of content, toc     |
\*----------------------------*/

.toc {
  background-color: 2c2c2c;
  margin-left: -19px;
  margin-right: -19px;
//  box-shadow: inset 0 1px 10px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 0.1), 0 -1px 0 rgba(0, 0, 0, 0.5);
}

.box h3{
	text-align:center;
	position:relative;
	top:80px;
}
.box {
	width:70%;
	height:200px;
	background:#FFF;
	margin:40px auto;
}

.wrapper{
  min-height: 100%;
  position: relative;
}

.content{
  padding-bottom: 250px;
}

.key {
  font-size: 1.0em;
  box-shadow: 1px 0 1px 0 #555, 0 1px 0 2px #888, 0 2px 0 2px #777;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin: 2px 3px;
  padding: 0px 5px;
}
